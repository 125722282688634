// We need to disable this because some endpoints
// expect the properties in snake_case.
/* eslint-disable camelcase */

import React from 'react';
import { useQuery, useQueryClient } from 'react-query';

import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  CircularProgress,
  Grid,
  Paper,
  AppBar,
  Tabs,
  Tab,
} from '@material-ui/core';

import Button from 'components/CustomButtons/Button';

import { validateDateRange } from 'helpers/lib/validation';
import { updatePrice, cancelPrice } from 'helpers/api/subscriptions';

import { getCurrentSubscription } from 'helpers/api/licences';

import CurrentPlan from './CurrentPlan';
import SubscriptionHistory from './SubscriptionHistory';
import CustomPriceModalContent from './CustomPriceModalContent';

const Licences = ({
  account: {
    subscriptions,
    currentPlan,
    subscriptionTypes,
    id: organizationId,
  },
}) => {
  const [currentSubscription, setCurrentSubscription] = React.useState({});

  const { data } = useQuery(
    ['account.currentSubscription', organizationId],
    async () =>
      getCurrentSubscription({
        organizationId: parseInt(organizationId),
      }),
    { placeholderData: {} }
  );

  React.useEffect(() => {
    if (typeof data !== 'undefined') {
      setCurrentSubscription(data);
    }
  }, [data]);

  const queryClient = useQueryClient();
  const [selectedTabIndex, setSelectedTabIndex] = React.useState(0);
  const [isLoading, setIsLoading] = React.useState(false);
  const [deletePriceModalIsOpen, setDeletePriceModalIsOpen] =
    React.useState(false);
  const [customPriceModalIsOpen, setCustomPriceModalIsOpen] =
    React.useState(false);
  const [selectedPrice, setSelectedPrice] = React.useState(null);

  const handleTabChange = (_, newIndex) => {
    setSelectedTabIndex(newIndex);
  };

  const [subscriptionPrice, setSubscriptionPrice] = React.useState({
    ppu: 0,
    type: 2,
    dt_start: '',
    dt_end: '',
  });

  const dateIsValid = React.useMemo(() => {
    return validateDateRange(
      subscriptionPrice.dt_start,
      subscriptionPrice.dt_end
    );
  }, [subscriptionPrice.dt_start, subscriptionPrice.dt_end]);

  const validateData = () => {
    const parsedPPU = parseFloat(subscriptionPrice.ppu);
    const ppuIsNumber = !Number.isNaN(parsedPPU) && parsedPPU >= 0;

    if (!dateIsValid) {
      console.error('The date range is invalid.');
      return false;
    }

    if (!ppuIsNumber) {
      console.error('The custom price is invalid.');
      return false;
    }

    return true;
  };

  const submitNewPrice = () => {
    const isValid = validateData();

    if (isValid) {
      setIsLoading(true);
      updatePrice({ organizationID: organizationId, ...subscriptionPrice })
        .then(() => {
          setIsLoading(false);
          queryClient.invalidateQueries('account');
          setCustomPriceModalIsOpen(false);
        })
        .catch((updatePriceError) => {
          console.error({ updatePriceError });
          setIsLoading(false);
        });
    }
  };

  return (
    <>
      <Paper variant="outlined">
        <Grid container>
          <Grid item xs={12}>
            <AppBar position="static">
              <Tabs value={selectedTabIndex} onChange={handleTabChange}>
                {['Current Plan', 'Subscription History'].map((tab) => (
                  <Tab key={tab} label={tab} />
                ))}
              </Tabs>
            </AppBar>
          </Grid>
          <Grid item xs={12}>
            {
              [
                <Box p={2} key="current-plan">
                  <CurrentPlan
                    currentSubscription={currentSubscription}
                    currentPlan={currentPlan}
                    subscriptionTypes={subscriptionTypes}
                    isLoading={isLoading}
                    setSelectedPrice={setSelectedPrice}
                    setDeletePriceModalIsOpen={setDeletePriceModalIsOpen}
                    setCustomPriceModalIsOpen={setCustomPriceModalIsOpen}
                  />
                </Box>,
                <Box key="subscription-history">
                  <SubscriptionHistory subscriptions={subscriptions} />
                </Box>,
              ][selectedTabIndex]
            }
          </Grid>
        </Grid>
      </Paper>
      <Dialog
        open={deletePriceModalIsOpen}
        onClose={() => setDeletePriceModalIsOpen(false)}
      >
        <DialogTitle>Delete Price</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure you want to delete this price?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletePriceModalIsOpen(false)}>
            Cancel
          </Button>
          <Button
            color="danger"
            onClick={() => {
              setDeletePriceModalIsOpen(false);
              cancelPrice(selectedPrice).then((response) => {
                if (response.success) {
                  queryClient.invalidateQueries('account');
                }
              });
            }}
          >
            Delete Price
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={customPriceModalIsOpen}
        onClose={() => setCustomPriceModalIsOpen(false)}
      >
        <DialogTitle>Custom Price</DialogTitle>
        <DialogContent>
          <CustomPriceModalContent
            subscriptionPrice={subscriptionPrice}
            subscriptionTypes={subscriptionTypes}
            setSubscriptionPrice={setSubscriptionPrice}
            dateIsValid={dateIsValid}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setCustomPriceModalIsOpen(false)}>
            Cancel
          </Button>
          <Button
            onClick={submitNewPrice}
            color={isLoading ? 'github' : 'info'}
            disabled={isLoading}
          >
            Set Price
            {isLoading && <CircularProgress size={24} />}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Licences;
